import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false;

import router from './router';
import './assets/css/index.css';
import cookie from 'js-cookie';
Vue.prototype.cookie = cookie;
import './axios';
import store from './store';
import headers from './components/pc/header'
Vue.component('headers',headers)
import 'font-awesome/css/font-awesome.min.css'

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
