import { post, get, cookie, url } from '../axios';
// 修改密码
export function editPassword(data) {
    return post(url + 'edit_user_password', data)
}

// 添加修改
export function handle(data) {
    return post(url + 'handle_user', data)
}
// 搜索列表
export function search(data) {
    return post(url + 'search_user', data)
}

// 删除用户
export function del(data) {
    return post(url + 'del', data)
}

// 删除用户对应的记录
export function delList(data) {
    return post(url + 'del_user_list', data)
}