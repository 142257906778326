import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: '',
        url: process.env.NODE_ENV == 'development' ? '/admin/' : '/admin/Index/'
    },
    
})