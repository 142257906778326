<template>
  <div class="header after">
    <div class="title f-l" @click="$router.push('/')">{{user.username}}</div>
    <div class="user f-r">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-avatar size="large" :src="require('/src/assets/user.png')"></el-avatar>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="password.show = true">
            <i class="fa fa-key"></i>
            修改密码
          </el-dropdown-item>
          <el-dropdown-item @click.native="exit">
            <i class="fa fa-sign-out"></i>
            退出系统
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-link class="editPassword f-r" :underline="false" @click="$router.push({ path: '/user' })" v-if="user.username == 'admin'"
      >用户管理</el-link
    >
    <el-link class="editPassword f-r" :underline="false" @click="$router.push({ path: '/banner' })">横幅管理</el-link>
    <el-link class="editPassword f-r" :underline="false" @click="goQrCode">二维码</el-link>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="password.show" width="40%" @closed="clearPassword" :append-to-body="true">
      <el-form :model="password.list" :rules="password.rules" ref="password.list">
        <el-form-item label="原密码" label-width="100px" prop="old">
          <el-input v-model="password.list.old" autocomplete="off" size="small" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" label-width="100px" prop="new">
          <el-input v-model="password.list.new" autocomplete="off" size="small" show-password></el-input>
        </el-form-item>
        <el-form-item label-width="100px"></el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="password.show = false" size="small">取 消</el-button>
        <el-button type="primary" @click="editPassword" size="small">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { editPassword } from "@/api/user";
export default {
  data() {
    return {
      user: this.$store.state.user,
      // 修改密码
      password: {
        show: false,
        list: {
          old: "",
          new: "",
        },
        rules: {
          old: [{ required: true, message: "请输入原密码", trigger: "blur" }],
          new: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        },
      },
    };
  },
  methods: {
    // 获取二维码
    goQrCode() {
      window.open("/qrcode?id=" + this.user.id+'&username='+this.user.username);
    },
    // 修改密码
    editPassword() {
      this.$refs["password.list"].validate((valid) => {
        if (valid) {
          var data = {
            id: this.user.id,
            old: this.password.list.old,
            new: this.password.list.new,
          };
          editPassword(data).then((res) => {
            if (res.state) {
              this.password.show = false;
              this.$message({ message: res.info, type: "success" });
            }
          });
        }
      });
    },
    // 重置修改密码表单
    clearPassword() {
      this.password.list = {
        old: "",
        new: "",
      };
      this.$refs["password.list"].resetFields();
    },
    // 退出
    exit() {
      this.cookie.remove("username");
      this.$router.replace("/login");
    },
  },
};
</script>
<style scoped>
.header {
  line-height: 60px;
  padding: 0 15px;
  background-color: #fff;
}
.header .user {
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
  overflow: hidden;
}
.header .user >>> .el-dropdown {
  height: 40px;
}
.header .user >>> .el-avatar {
  background-color: transparent;
}
.header > .title {
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}
.header .editPassword {
  margin-right: 20px;
}
</style>