import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import el from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(el);
Vue.prototype.axios = axios;
Vue.prototype.post = axios.post;
Vue.prototype.get = axios.get;
Vue.prototype.qs = qs;

// 请求拦截器
axios.interceptors.request.use(res => {
    // 序列化请求参数 
    res.data = qs.stringify(res.data);
    return res;
})
// 响应拦截器
axios.interceptors.response.use(res => {
    let data = res.data;
    // 当后台返回0时(代表报错提示,不是程序出错)
    if (!data.state) el.Message({ type: 'error', message: data.info });
    return res.data;
}, function (e) {
    let r = e.response;
    el.Message({ type: 'error', message: '错误 , ' + r.status + ' : ' + r.statusText });
    return { state: 0 };
})