import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import store from '@/store';
import cookie from 'js-cookie';
import { isLogin } from '@/api/login';
let router = new VueRouter({
    mode: 'history',
    routes: [
        // pc版
        {
            path: '/', component: () => import('@/components/pc/box'), children: [
                { path: '/', meta: { title: '首页' }, component: () => import('@/components/pc/index') },
                { path: 'user', meta: { title: '用户管理' }, component: () => import('@/components/pc/user') },
                { path: 'banner', meta: { title: '横幅' }, component: () => import('@/components/pc/banner') },
            ]
        },
        { path: '/qrcode', meta: { title: '二维码' }, component: () => import('@/components/pc/qrcode') },
        { path: '/login', meta: { title: '登录' }, component: () => import('@/components/pc/login') },

        // 手机版
        { path: '/phone/login', meta: { title: '登录' }, component: () => import('@/components/phone/login') },
        { path: '/phone', meta: { title: '首页' }, component: () => import('@/components/phone/index') }
    ]
})

// 路由前置验证
router.beforeEach((to, from, next) => {
    // 修改页面标题和头部标题
    document.title = to.meta.title;
    let user = cookie.get('username'),
        login = ['/login', '/phone/login'];
    if (login.indexOf(to.path) != '-1') {
        next();
    } else if (user) {
        // 去登录
        if (login.indexOf(to.path) != '-1' || store.state.user) {
            next();
        } else {
            // 没有数据时 重新获取数据
            isLogin().then(res => {
                if (res.state) {
                    store.state.user = res.data;
                    next();
                } else {
                    cookie.remove('username');
                    if (to.path.indexOf('phone') == -1) {
                        next('/login')
                    } else {
                        next('/phone/login')
                    }
                }
            })
        }
    } else {
        if (to.path.indexOf('phone') == -1) {
            next('/login')
        } else {
            next('/phone/login')
        }
    }
})


export default router;