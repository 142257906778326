import { post, get, cookie, url } from '../axios';


// 登录
export function login(data) {
    return post(url + 'login', data)
}

// 验证登录
export function isLogin() {
    let data = { username: cookie.get('username') }
    return post(url + 'is_login', data)
}